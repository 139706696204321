import React from 'react';
import {Link} from 'react-router-dom';

const Logo = () => {
    return (
        <div className='logo'>
            <Link to='/App'>
            <svg width="195" height="41" viewBox="0 0 195 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M62.4546 25.8068V11.4092C61.4448 11.9803 60.2649 12.3921 58.9148 12.6447C57.5647 12.8973 55.825 13.0455 53.6956 13.0895V13.8143C54.4858 13.8143 55.0731 13.8967 55.4573 14.0614C55.8414 14.2261 56.1158 14.5556 56.2805 15.0498C56.4451 15.544 56.5274 16.3182 56.5274 17.3725V25.4773C56.5274 26.4876 56.2914 27.1905 55.8195 27.5859C55.3475 27.9812 54.6395 28.1789 53.6956 28.1789V28.8708L54.6505 28.8378C57.2848 28.7719 58.9861 28.739 59.7545 28.739C60.413 28.739 61.9278 28.7829 64.2987 28.8708V28.1789C63.6181 28.1789 63.1407 28.0087 62.8662 27.6682C62.5918 27.3278 62.4546 26.7073 62.4546 25.8068Z" fill="#455E47"/>
                <path d="M79.8545 23.5335C78.8007 25.0051 77.4726 25.7409 75.8701 25.7409C74.8163 25.7409 73.9218 25.2906 73.1864 24.3901C72.4509 23.4895 72.0832 21.985 72.0832 19.8764C72.0832 19.3493 72.0942 18.9539 72.1162 18.6903H80.6118C80.6118 13.7704 78.5373 11.3104 74.3883 11.3104C71.8418 11.3104 69.7947 12.0626 68.247 13.5672C66.6994 15.0717 65.9255 17.3286 65.9255 20.3377C65.9255 23.4785 66.617 25.7628 68.0001 27.1905C69.3831 28.6182 71.2271 29.332 73.5321 29.332C75.3322 29.332 76.8085 28.7829 77.961 27.6847C79.1136 26.5865 79.9313 25.2686 80.4142 23.7311L79.8545 23.5335ZM71.9845 18.0644C72.204 14.0669 72.9284 12.0681 74.1578 12.0681C74.7285 12.0681 75.1511 12.6282 75.4255 13.7484C75.6999 14.8686 75.8262 16.3072 75.8042 18.0644H71.9845Z" fill="#455E47"/>
                <path d="M90.9811 11.5904C90.2238 11.4037 89.417 11.3104 88.5609 11.3104C87.4632 11.3104 86.4424 11.5025 85.4985 11.8869C84.5545 12.2713 83.7972 12.8259 83.2264 13.5507C82.6556 14.2755 82.3702 15.1102 82.3702 16.0546C82.3702 16.8673 82.5404 17.6086 82.8806 18.2785C83.2209 18.9484 83.6325 19.536 84.1155 20.0411C84.5984 20.5463 85.3009 21.2272 86.2229 22.0838C87.0352 22.8086 87.6224 23.3632 87.9846 23.7476C88.3468 24.132 88.6542 24.5658 88.9066 25.049C89.1591 25.5322 89.2853 26.0813 89.2853 26.6963C89.2853 27.2893 89.1042 27.7396 88.742 28.0471C88.3798 28.3546 87.9242 28.5084 87.3754 28.5084C86.2119 28.5084 85.246 27.9592 84.4777 26.861C83.7094 25.7628 83.2264 24.3132 83.0288 22.5121H82.3702C82.3702 25.653 82.3263 27.8494 82.2385 29.1014H82.8971C83.0947 28.3326 83.402 27.9483 83.8191 27.9483C84.0606 27.9483 84.3021 27.9922 84.5435 28.08C84.785 28.1679 85.0924 28.3107 85.4656 28.5084C86.3437 29.0575 87.4742 29.332 88.8572 29.332C90.0646 29.332 91.1787 29.1179 92.1995 28.6896C93.2203 28.2613 94.0271 27.6572 94.6198 26.8775C95.2125 26.0978 95.5089 25.2247 95.5089 24.2583C95.5089 23.1161 95.2235 22.1552 94.6527 21.3755C94.0819 20.5957 93.1819 19.7007 91.9525 18.6903C91.3598 18.2071 91.0415 17.9545 90.9976 17.9326C89.9658 17.1199 89.2085 16.4335 88.7255 15.8734C88.2426 15.3133 88.0011 14.6709 88.0011 13.9461C88.0011 13.375 88.1877 12.9247 88.5609 12.5953C88.9341 12.2658 89.3951 12.1011 89.9439 12.1011C91.1732 12.1011 92.1117 12.6008 92.7593 13.6001C93.4069 14.5995 93.8185 15.824 93.9941 17.2737H94.6527C94.6527 14.4622 94.6966 12.4854 94.7844 11.3433H94.1259C94.038 12.0462 93.7636 12.3976 93.3026 12.3976C93.149 12.3976 92.9514 12.3317 92.7099 12.1999C92.3148 11.9803 91.7385 11.7771 90.9811 11.5904Z" fill="#455E47"/>
                <path d="M113.501 13.4189C112.447 12.0132 111.064 11.3104 109.352 11.3104C107.025 11.3104 105.356 12.134 104.347 13.7813V11.4421C102.81 11.7496 100.9 11.9034 98.617 11.9034C97.9365 11.9034 97.256 11.8705 96.5754 11.8046V12.4964C97.256 12.4964 97.7334 12.7271 98.0078 13.1883C98.2823 13.6496 98.4195 14.3854 98.4195 15.3957V31.77C98.4195 32.6706 98.2823 33.2911 98.0078 33.6315C97.7334 33.9719 97.256 34.1422 96.5754 34.1422V34.834C98.9463 34.7462 100.461 34.7023 101.12 34.7023C101.888 34.7023 103.589 34.7352 106.224 34.8011L107.179 34.834V34.1422C106.235 34.1422 105.527 33.9445 105.055 33.5491C104.583 33.1538 104.347 32.4509 104.347 31.4406V28.706C105.115 29.1233 106.136 29.332 107.409 29.332C109.714 29.332 111.569 28.5797 112.974 27.0752C114.379 25.5706 115.081 23.0722 115.081 19.5799C115.081 16.8783 114.555 14.8246 113.501 13.4189ZM108.117 26.3833C107.579 27.6902 106.707 28.3436 105.499 28.3436C105.016 28.3436 104.632 28.2558 104.347 28.08V15.3957C104.347 14.7148 104.583 14.1492 105.055 13.699C105.527 13.2487 106.081 13.0236 106.718 13.0236C107.398 13.0236 107.936 13.5672 108.331 14.6544C108.726 15.7416 108.924 17.6361 108.924 20.3377C108.924 23.0612 108.655 25.0764 108.117 26.3833Z" fill="#455E47"/>
                <path d="M130.654 12.2988C130.182 11.6398 129.408 11.3104 128.332 11.3104C126.335 11.3104 124.919 12.134 124.084 13.7813V11.4092C122.548 11.7167 120.638 11.8705 118.355 11.8705C117.696 11.8705 117.016 11.8375 116.313 11.7716V12.4635C116.994 12.4635 117.471 12.6941 117.746 13.1554C118.02 13.6166 118.157 14.3524 118.157 15.3628V25.8067C118.157 26.7073 118.02 27.3278 117.746 27.6682C117.471 28.0087 116.994 28.1789 116.313 28.1789V28.8708C116.599 28.8708 116.829 28.8598 117.005 28.8378C119.376 28.7719 120.901 28.739 121.582 28.739C122.35 28.739 123.887 28.7719 126.192 28.8378C126.389 28.8598 126.631 28.8708 126.916 28.8708V28.1789C125.885 28.1789 125.155 27.9867 124.727 27.6023C124.298 27.218 124.084 26.5096 124.084 25.4773V15.5934C124.084 14.9564 124.271 14.3689 124.644 13.8308C125.017 13.2926 125.478 12.9028 126.027 12.6612C126.335 12.5074 126.686 12.4305 127.081 12.4305C125.961 13.1773 125.402 14.1987 125.402 15.4945C125.402 16.2194 125.654 16.7959 126.159 17.2242C126.664 17.6525 127.388 17.8667 128.332 17.8667C129.232 17.8667 129.962 17.5757 130.522 16.9936C131.082 16.4116 131.362 15.6263 131.362 14.6379C131.362 13.7374 131.126 12.9577 130.654 12.2988Z" fill="#455E47"/>
                <path d="M145.896 23.5335C144.842 25.0051 143.514 25.7409 141.912 25.7409C140.858 25.7409 139.964 25.2906 139.228 24.3901C138.493 23.4895 138.125 21.985 138.125 19.8764C138.125 19.3493 138.136 18.9539 138.158 18.6903H146.654C146.654 13.7704 144.579 11.3104 140.43 11.3104C137.884 11.3104 135.836 12.0626 134.289 13.5672C132.741 15.0717 131.967 17.3286 131.967 20.3377C131.967 23.4785 132.659 25.7628 134.042 27.1905C135.425 28.6182 137.269 29.332 139.574 29.332C141.374 29.332 142.85 28.7829 144.003 27.6847C145.155 26.5865 145.973 25.2686 146.456 23.7311L145.896 23.5335ZM138.026 18.0644C138.246 14.0669 138.97 12.0681 140.2 12.0681C140.77 12.0681 141.193 12.6282 141.467 13.7484C141.742 14.8686 141.868 16.3072 141.846 18.0644H138.026Z" fill="#455E47"/>
                <path d="M157.023 11.5904C156.266 11.4037 155.459 11.3104 154.603 11.3104C153.505 11.3104 152.484 11.5025 151.54 11.8869C150.597 12.2713 149.839 12.8259 149.268 13.5507C148.698 14.2755 148.412 15.1102 148.412 16.0546C148.412 16.8673 148.582 17.6086 148.923 18.2785C149.263 18.9484 149.675 19.536 150.157 20.0411C150.64 20.5463 151.343 21.2272 152.265 22.0838C153.077 22.8086 153.664 23.3632 154.027 23.7476C154.389 24.132 154.696 24.5658 154.949 25.049C155.201 25.5322 155.327 26.0813 155.327 26.6963C155.327 27.2893 155.146 27.7396 154.784 28.0471C154.422 28.3546 153.966 28.5084 153.417 28.5084C152.254 28.5084 151.288 27.9592 150.52 26.861C149.751 25.7628 149.268 24.3132 149.071 22.5121H148.412C148.412 25.653 148.368 27.8494 148.281 29.1014H148.939C149.137 28.3326 149.444 27.9483 149.861 27.9483C150.103 27.9483 150.344 27.9922 150.586 28.08C150.827 28.1679 151.134 28.3107 151.508 28.5084C152.386 29.0575 153.516 29.332 154.899 29.332C156.107 29.332 157.221 29.1179 158.242 28.6896C159.262 28.2613 160.069 27.6572 160.662 26.8775C161.254 26.0978 161.551 25.2247 161.551 24.2583C161.551 23.1161 161.265 22.1552 160.695 21.3755C160.124 20.5957 159.224 19.7007 157.995 18.6903C157.402 18.2071 157.084 17.9545 157.04 17.9326C156.008 17.1199 155.25 16.4335 154.768 15.8734C154.285 15.3133 154.043 14.6709 154.043 13.9461C154.043 13.375 154.23 12.9247 154.603 12.5953C154.976 12.2658 155.437 12.1011 155.986 12.1011C157.215 12.1011 158.154 12.6008 158.801 13.6001C159.449 14.5995 159.861 15.824 160.036 17.2737H160.695C160.695 14.4622 160.739 12.4854 160.826 11.3433H160.168C160.08 12.0462 159.806 12.3976 159.345 12.3976C159.191 12.3976 158.993 12.3317 158.752 12.1999C158.357 11.9803 157.78 11.7771 157.023 11.5904Z" fill="#455E47"/>
                <path d="M172.052 11.5904C171.294 11.4037 170.488 11.3104 169.631 11.3104C168.534 11.3104 167.513 11.5025 166.569 11.8869C165.625 12.2713 164.868 12.8259 164.297 13.5507C163.726 14.2755 163.441 15.1102 163.441 16.0546C163.441 16.8673 163.611 17.6086 163.951 18.2785C164.291 18.9484 164.703 19.536 165.186 20.0411C165.669 20.5463 166.371 21.2272 167.293 22.0838C168.106 22.8086 168.693 23.3632 169.055 23.7476C169.417 24.132 169.725 24.5658 169.977 25.049C170.23 25.5322 170.356 26.0813 170.356 26.6963C170.356 27.2893 170.175 27.7396 169.813 28.0471C169.45 28.3546 168.995 28.5084 168.446 28.5084C167.282 28.5084 166.317 27.9592 165.548 26.861C164.78 25.7628 164.297 24.3132 164.099 22.5121H163.441C163.441 25.653 163.397 27.8494 163.309 29.1014H163.968C164.165 28.3326 164.473 27.9483 164.89 27.9483C165.131 27.9483 165.373 27.9922 165.614 28.08C165.856 28.1679 166.163 28.3107 166.536 28.5084C167.414 29.0575 168.545 29.332 169.928 29.332C171.135 29.332 172.249 29.1179 173.27 28.6896C174.291 28.2613 175.098 27.6572 175.69 26.8775C176.283 26.0978 176.579 25.2247 176.579 24.2583C176.579 23.1161 176.294 22.1552 175.723 21.3755C175.153 20.5957 174.252 19.7007 173.023 18.6903C172.43 18.2071 172.112 17.9545 172.068 17.9326C171.036 17.1199 170.279 16.4335 169.796 15.8734C169.313 15.3133 169.072 14.6709 169.072 13.9461C169.072 13.375 169.258 12.9247 169.631 12.5953C170.005 12.2658 170.466 12.1011 171.014 12.1011C172.244 12.1011 173.182 12.6008 173.83 13.6001C174.477 14.5995 174.889 15.824 175.065 17.2737H175.723C175.723 14.4622 175.767 12.4854 175.855 11.3433H175.196C175.109 12.0462 174.834 12.3976 174.373 12.3976C174.22 12.3976 174.022 12.3317 173.78 12.1999C173.385 11.9803 172.809 11.7771 172.052 11.5904Z" fill="#455E47"/>
                <path d="M192.794 13.4354C191.323 12.0187 189.237 11.3104 186.537 11.3104C183.837 11.3104 181.752 12.0187 180.281 13.4354C178.81 14.8521 178.074 17.1528 178.074 20.3377C178.074 23.5225 178.81 25.8177 180.281 27.2234C181.752 28.6292 183.837 29.332 186.537 29.332C189.237 29.332 191.323 28.6292 192.794 27.2234C194.265 25.8177 195 23.5225 195 20.3377C195 17.1528 194.265 14.8521 192.794 13.4354ZM184.858 14.0449C185.275 12.6612 185.835 11.9693 186.537 11.9693C187.24 11.9693 187.799 12.6612 188.217 14.0449C188.634 15.4287 188.842 17.5262 188.842 20.3377C188.842 23.1491 188.634 25.2412 188.217 26.6139C187.799 27.9867 187.24 28.6731 186.537 28.6731C185.835 28.6731 185.275 27.9867 184.858 26.6139C184.441 25.2412 184.232 23.1491 184.232 20.3377C184.232 17.5262 184.441 15.4287 184.858 14.0449Z" fill="#455E47"/>
                <ellipse cx="27.2248" cy="20.5" rx="20.8188" ry="20.5" fill="url(#paint0_radial)"/>
                <g filter="url(#filter0_i)">
                <ellipse cx="27.4533" cy="20.7253" rx="10.9814" ry="10.8132" fill="#F7EFDE"/>
                </g>
                <rect y="17.5714" width="9.15113" height="5.85714" rx="2.92857" fill="#455E47"/>
                <defs>
                <filter id="filter0_i" x="16.4719" y="9.91208" width="23.9627" height="23.6264" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="2" dy="2"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
                </filter>
                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.2248 20.5) rotate(90) scale(20.5 20.8188)">
                <stop offset="0.484375" stop-color="#455E47"/>
                <stop offset="0.963542" stop-color="#455E47"/>
                </radialGradient>
                </defs>
         </svg>
         </Link>
        </div>

    )
}

export default Logo;