import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <p>1espresso.coffe | Enjoy a glorious espresso with your new recipe!</p>
    </div>
  );
};

export default Footer;
