import React from "react";
import "../../styles/loader.css";

const Loader = ({ size }) => {
  return (
    <svg
      className='loader'
      xmlns='http://www.w3.org/2000/svg'
      width='10em'
      height='10em'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <circle cx='12' cy='12' r='10' />
    </svg>
  );
};

export default Loader;
