import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "one-espresso.firebaseapp.com",
  databaseURL: "https://one-espresso.firebaseio.com",
  projectId: "one-espresso",
  storageBucket: "one-espresso.appspot.com",
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
firebase.initializeApp(config);
export const auth = firebase.auth();
export const db = firebase.firestore();
export const analytics = firebase.analytics();
